import request from '@/utils/request'

export function pageInit(params) {
    return request({
        method:'get',
        url:'/api/financeMoneyFlowing/pageInit',
        params
    });
}

export function listPage(params) {
    return request({
        method:'get',
        url:'/api/financeMoneyFlowing/listPage',
        params
    });
}
//导出
export function exportExcel(params) {
    return request({
        method:'get',
        url:'/api/financeMoneyFlowing/exportExcel',
        params
    });
}

//查看下载报告
export function exportRecort() {
    return request({
        url:'/api/financeMoneyFlowing/exportRecord',
        method:'get',
    });
}